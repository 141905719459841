import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { PROFILE_OPERATION_TYPE } from "../../common/constants";
import { isValidArray } from "../../common/utils";
import { companiesOperationHandler } from "../../services/userProfile/companies.service";
import { profileOperationHandler } from "../../services/userProfile/userProfile.service";
import { UserProfileContext } from "../../store/context/profile.context";

const useCompaniesCustomHook = () => {
  const profileContext = useContext(UserProfileContext);
  const [searchObjectFromCompanies, setSearchObjectFromCompanies] = useState();
  const [companies, setCompanies] = useState();
  const { profileInfo = false, setProfileInfo = () => {} } = profileContext;
  const { user } = useAuth0();

  const updateSearchObjectFromCompanies = ({
    updatedIndex,
    days,
    initialCompaniesData,
    wordsToMatch,
    wordsNotToMatch,
  }) => {
    const companiesData = initialCompaniesData || companies;

    if (isValidArray(companiesData, true)) {
      const company = companiesData[updatedIndex];

      setSearchObjectFromCompanies({
        twitter: company.mediaSearchKeys.twitter,
        trustPilotcompanyName: company.mediaSearchKeys.trustPilotcompanyName,
        facebook: company.mediaSearchKeys.facebook,
        instagram: company.mediaSearchKeys.instagram,
        tiktok: company.mediaSearchKeys.tiktok,
        lang: company.language,
        linkedIn: company.mediaSearchKeys.linkedin,
        articles: company.mediaSearchKeys.news,
        reddit: company.mediaSearchKeys.reddit,
        youtube: company.mediaSearchKeys.youtube,
        days: days || company.days,
        mainSearchTerm: company.masterSearch,
        wordsToMatch: wordsToMatch || undefined,
        wordsNotToMatch: wordsNotToMatch || undefined,
        tiktokType: company.tiktokType,
        instagramType: company.instagramType,
        youtubeType: company.youtubeType,
        tiktokOptions: company.tiktokOptions,
        instagramOptions: company.instagramOptions,
        id: company.id,
      });
      setCompaniesData(companiesData, updatedIndex, days || company.days);
    }
  };

  const setCompaniesData = (companiesData, updatedIndex, days) => {
    if (companiesData.length) {
      const updatedCompanies = [...companiesData].filter((company, index) => {
        if (company.masterSearch) {
          if (index !== updatedIndex && company.selected) {
            company.selected = false;
          }
          if (index === updatedIndex) {
            company.selected = true;
          }
          company.days = days;
          return company;
        }
      });
      setCompanies([...updatedCompanies]);
    }
  };

  const updateCompaniesData = () => {
    profileOperationHandler({
      params: user.name,
      callbackFn: setProfileInfo,
      profileOperationType: PROFILE_OPERATION_TYPE.getProfileInfo,
    });
  };

  const addCompany = (companyData) => {
    companiesOperationHandler({
      operationType: PROFILE_OPERATION_TYPE.addCompany,
      data: companyData,
      callbackFn: updateCompaniesData,
    });
  };
  const removeCompany = (id) => {
    companiesOperationHandler({
      operationType: PROFILE_OPERATION_TYPE.removeCompany,
      id,
      callbackFn: updateCompaniesData,
    });
  };
  const editCompany = (companyData, id) => {
    companiesOperationHandler({
      operationType: PROFILE_OPERATION_TYPE.editCompany,
      data: companyData,
      callbackFn: updateCompaniesData,
      id,
    });
  };

  useEffect(() => {
    if (profileInfo && profileInfo.companies) {
      const companiesData = [...profileInfo.companies];
      if (isValidArray(companiesData, true)) {
        updateSearchObjectFromCompanies({
          updatedIndex: 0,
          initialCompaniesData: companiesData,
          days: 2,
        });
      }
    }
  }, []);

  return {
    companies,
    searchObjectFromCompanies,
    updateSearchObjectFromCompanies,
    addCompany,
    removeCompany,
    editCompany,
  };
};
export default useCompaniesCustomHook;
