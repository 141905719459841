import React, { useState } from "react";

const BooleanSearch = ({
  setWordsToBeExcludedInSearchObject,
  setWordsToBeIncludedInSearchObject,
}) => {
  const [isItemsVisibleIncluded, setItemsVisibleIncluded] = useState(true);
  const [isItemsVisibleExcluded, setItemsVisibleExcluded] = useState(true);

  const [inputValueIncluded, setInputValueIncluded] = useState("");
  const [inputValueExcluded, setInputValueExcluded] = useState("");

  const toggleItemsVisibilityIncluded = () => {
    if (inputValueIncluded === "") {
      setItemsVisibleIncluded(!isItemsVisibleIncluded);
    }
  };

  const toggleItemsVisibilityExcluded = () => {
    if (inputValueExcluded === "") {
      setItemsVisibleExcluded(!isItemsVisibleExcluded);
    }
  };

  const handleInputChangeIncluded = (event) => {
    setWordsToBeIncludedInSearchObject(event.target.value);
    setInputValueIncluded(event.target.value);
    setItemsVisibleIncluded(false);
  };

  const handleInputChangeExcluded = (event) => {
    setWordsToBeExcludedInSearchObject(event.target.value);
    setInputValueExcluded(event.target.value);
    setItemsVisibleExcluded(false);
  };
  return (
    <section className=" border border-solid p-8">
      <div className="p-4 space-y-4">
        <div className="w-full flex flex-col md:flex-row items-start">
          <div className="keyword mr-6">
            <div className="title">Included Keywords</div>
          </div>

          <div
            // className="input-label flex items-center space-x-2"
            onClick={toggleItemsVisibilityIncluded}
            className={`w-full rounded-md relative flex items-center min-w-[300px] mb-4 bg-background shadow-lg`}
            style={{ maxWidth: "672px" }}
          >
            <input
              //   value={inputValueIncluded}
              onChange={handleInputChangeIncluded}
              type="text"
              name="searchbox"
              className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
                       text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
              placeholder="Start typing in any topic/company name"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row items-start">
          <div className="keyword mr-6">
            <div className="title">Excluded keywords</div>
          </div>

          <div
            className={`w-full rounded-md relative flex items-center min-w-[300px] mb-4 bg-background shadow-lg`}
            onClick={toggleItemsVisibilityExcluded}
            style={{ maxWidth: "672px" }}
          >
            <input
              className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
                      text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
              //   value={inputValueExcluded}
              placeholder="Start typing in any topic/company name"
              onChange={handleInputChangeExcluded}
              autoComplete="off"
              type="text"
              name="searchbox"
            />
            {/* {(inputValueExcluded === "" || isItemsVisibleExcluded) && (
                      <div className="items-container flex items-center space-x-2">
                        <div className="items px-2 py-1 text-xs font-medium text-gray-700 bg-gray-200 rounded">
                          Nikola Tesla
                        </div>
                        <div className="items px-2 py-1 text-xs font-medium text-gray-700 bg-gray-200 rounded">
                          Tesla 19th century
                        </div>
                      </div>
                    )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BooleanSearch;
