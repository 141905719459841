import React, { useState } from 'react';

import { CiCircleInfo } from 'react-icons/ci';
import Tooltip from '../../../common/Tooltip/Tooltip';

const IntervalSelector = ({
  handleIntervalType,
  selectedOption,
  onIntervalChange,
}) => {
  const handleClick = (e, interval) => {
    e.preventDefault();
    handleIntervalType(e, interval);
    // onIntervalChange(e, interval);
  };

  return (
    <div className="flex flex-col ">
      <label className="block text-md mb-1 ml-1">Arrange by</label>
      <form className="flex">
        <button
          className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
            selectedOption === 'day'
              ? 'bg-primary text-onprimary'
              : 'bg-onprimary'
          } `}
          onClick={(e) => handleClick(e, 'day')}
          value="days"
        >
          <div className="flex items-center py-1">
            {selectedOption === 'day' && (
              <span className="material-icons text-sm inline-block pr-1">
                check
              </span>
            )}
            <span className="text-sm border-l border-white inline-block pl-1 whitespace-nowrap">
              Daily
            </span>
          </div>
        </button>
        <button
          className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
            selectedOption === 'week'
              ? 'bg-primary text-onprimary'
              : 'bg-onprimary'
          } `}
          onClick={(e) => handleClick(e, 'week')}
        >
          <div className="flex items-center py-1">
            {selectedOption === 'week' && (
              <span className="material-icons text-sm inline-block pr-1">
                check
              </span>
            )}
            <span
              value="week"
              className="text-sm border-l border-white inline-block pl-1 whitespace-nowrap"
            >
              Weekly
            </span>
          </div>
        </button>
        <button
          className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
            selectedOption === 'month'
              ? 'bg-primary text-onprimary'
              : 'bg-onprimary'
          } `}
          onClick={(e) => handleClick(e, 'month')}
          value="months"
        >
          <div className="flex items-center py-1">
            {selectedOption === 'month' && (
              <span className="material-icons text-sm inline-block pr-1">
                check
              </span>
            )}
            <span className="text-sm border-l border-white inline-block pl-1 whitespace-nowrap">
              Monthly
            </span>
          </div>
        </button>
        <div className=" py-2 px-2 h-9  flex justify-start items-center">
          <Tooltip text="Analyse data by Days/Week/Month" align="start">
            <div className="cursor-pointer relative">
              <CiCircleInfo
                className="text-[20px] font-bold text-green-700"
                // onMouseOver={() => setTooltip(!Tooltip)}
                // onMouseLeave={() => setTooltip(!Tooltip)}
              />
              {/* {Tooltip && ( */}
              {/* <div className="absolute text-xs pt-[0.1rem] text-secondary  top-0 left-7 whitespace-nowrap">
            Analyse data by Days/Week/Month
          </div> */}
            </div>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};

export default IntervalSelector;
