/* eslint-disable no-undef */

import React, { memo, useContext, useEffect, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { ChatgptContext } from './context_chatgpt/ChatgptContext';
import Dialog from '../common/Dialog';
import { GoLinkExternal } from 'react-icons/go';
import { LoaderBoxes } from '../modules/App/LayoutComponents/Loader/Loader';
import ReactModal from 'react-modal-resizable-draggable';
import { useNavigate } from 'react-router-dom';

// Icon from react-icons

const systemMessage = {
  role: 'assistant',
  content:
    'Summarize the provided data and create concise summaries or key points.',
};

let chartName = '';

const ChatgptSummary = () => {
  const {
    setSidebarStatus,
    sidebarStatus,
    summaryStatus,
    setSummaryStatus,
    ChatgptData,
    convoId,
    setconvoId,
    PillsName,
    socialMediaType,
    mainSearchTerm,
    subtype,
    SearchObject,
  } = useContext(ChatgptContext);

  const [lang, setlang] = useState('');
  // listning with user mic
  const [modal, setmodal] = useState(false);

  const [summary, setSummary] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [topPillsName, setTopPillsName] = useState('Overall Summary');
  const [isPromptData, setIsPromptData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setshowModal] = useState(false);
  const [showModalMessage, setshowModalMessage] = useState('');
  const [ModalTitle, setModalTitle] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setPrompt(process.env.REACT_APP_CHATGPT_SUMMARIZE_PROMPT);
    return () => {
      setconvoId('');
    };
  }, []);

  useEffect(() => {
    if (ChatgptData?.title) {
      handleFeedBtn();
    }
  }, [ChatgptData]);

  useEffect(() => {
    setSummary([]);
  }, [socialMediaType, ChatgptData]);

  const handleSummarizeBtn = () => {
    setPrompt(`${process.env.REACT_APP_CHATGPT_SUMMARIZE_PROMPT}`);
    setSidebarStatus(false);
    setconvoId('');
    chartName = '';
  };

  async function feedToChatGPT(theJsonData) {
    const apiRequestBody = {
      model: 'gpt-3.5-turbo',
      messages: [
        systemMessage,
        {
          role: 'user',
          content: `${
            lang ? `In ${lang} ${prompt}` : prompt
          } \n. ${JSON.stringify(theJsonData)}`,
        },
      ],
    };

    if (!chartName) {
      chartName = prompt;
    }

    try {
      // https://api.openai.com/v1/chat/completions
      await fetch(
        'https://genai-002.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2024-02-15-preview',
        {
          method: 'POST',
          headers: {
            // Authorization:
            //   "Bearer " + process.env.REACT_APP_CHATGPT_SUMMARIZE_API_KEY,
            'Content-Type': 'application/json',
            'api-key': '660806b0d8824f8f9cc83bc456f630d4',
            max_tokens: 800,
            temperature: 0.7,
            frequency_penalty: 0,
            presence_penalty: 0,
            top_p: 0.95,
            stop: null,
          },
          body: JSON.stringify(apiRequestBody),
        },
      )
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data?.choices) {
            setSummary((prev) => [
              { user: prompt, content: data?.choices[0]?.message?.content },
            ]);
          } else {
            setModalTitle('Error : 403');
            setshowModalMessage(`Please refresh and Login if needed `);
            setshowModal(true);
          }

          const RequestBody = {
            _id: convoId,
            chatId: data?.id,
            chartName: chartName,
            sourceAPI: JSON.stringify(SearchObject),
            socialMediaSubType: subtype,
            conversationId: convoId,
            promptName: topPillsName,
            createdTime: data?.created,
            socialMediaType: socialMediaType,
            handlerUsed: mainSearchTerm.toLowerCase(),
            interval: SearchObject?.interval,
            socialMediaPillsName: ChatgptData?.title,
            messages: [
              { role: 'user', content: prompt },
              { role: 'assistant', content: data.choices[0].message.content },
            ],
            usage: data?.usage,
          };

          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (prompt?.length > 0) {
      setIsPromptData(true);
    } else {
      setIsPromptData(false);
    }
  }, [prompt]);

  const handleFeedBtn = async () => {
    setIsLoading(true);
    if (ChatgptData != null && summaryStatus) {
      await feedToChatGPT(ChatgptData.excelData.slice(0, 40));
    }
  };

  const overlayStyle = {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
    zIndex: 40, // Set the overlay background color to transparent
  };

  return (
    <ReactModal
      initWidth={700}
      intiHeight={400}
      isOpen={summaryStatus}
      onRequestClose={() => setSidebarStatus(false)}
      disableResize={false}
      customStyles={{
        overlay: overlayStyle,
      }}
    >
      <div className="w-full h-full flex flex-col gap-2">
        <p className="text-[20px] text-black font-medium text-center pt-2">
          Summary By HaiX AI
        </p>
        <div className="w-full flex  items-center justify-between px-5 py-4 border-b-[1.5px] border-gray-400">
          <p className="text-[20px] font-medium">
            {ChatgptData &&
              mainSearchTerm?.toUpperCase() +
                ': ' +
                (socialMediaType !== undefined ? socialMediaType : '') +
                (subtype === 'userposts' || subtype === 'manual_users'
                  ? ' User Account '
                  : subtype === 'userhashtags' || subtype === 'manual_hashtags'
                  ? ' User Hashtag '
                  : subtype === 'manual_tags'
                  ? ' User Tag '
                  : subtype === 'keywordposts'
                  ? ' Keywords '
                  : '') +
                (subtype ? ' - ' : '') +
                (ChatgptData.title !== undefined
                  ? ' "' + ChatgptData.title + '" '
                  : '') +
                ' data - "' +
                (SearchObject.updatedDays || SearchObject.days) +
                ' days' +
                '"' +
                (SearchObject.interval !== undefined
                  ? ' by ' + SearchObject.interval
                  : '')}
          </p>
          {/* <div className="flex justify-center items-center"> */}
          {/* <button title="Disclaimer" onClick={() => setmodal(true)}>
              <FcDisclaimer className="text-lg" />
            </button> */}
          {/* <button
            title="Open Chat History in HaiX AI Assistant Page"
            alt="Default Prompt"
            className="w-[30px] h-[30px] rounded-full flex items-center justify-center mx-2"
            onClick={() => {
              navigate('/haix-genai/summary', {
                state: {
                  message: summary,
                  pillname: ChatgptData?.title,
                  socialMediaType,
                  handler: mainSearchTerm,
                  ChatgptData,
                  chartData: ChatgptData.excelData.slice(0, 40),
                },
              });
              setSummaryStatus(false);
              handleSummarizeBtn();
            }}
          >
            <GoLinkExternal className="text-[16px] text-green-600" />
          </button> */}
          <button
            title="Close"
            onClick={() => setSummaryStatus(false)}
            className="mx-4"
          >
            <AiOutlineClose color="#ff0000" />
          </button>
          {/* </div> */}
        </div>
        <div className="overflow-x-auto h-auto">
          {!isLoading &&
            summary.reverse().map((line, index) => {
              return (
                <div key={index} className="border-b-2 my-2 mx-4">
                  <div className="mt-2">
                    <span className="font-bold">
                      Summary by HaiX AI Assistant:{' '}
                    </span>
                  </div>
                  {line?.content?.split('\n').map((item, index) => (
                    <div key={index}>
                      <p>{item}</p>
                      <br />
                    </div>
                  ))}
                </div>
              );
            })}
          {isLoading && <LoaderBoxes message="Fetching" />}
        </div>
        <div className="mx-4 my-2 flex items-center">
          For more details Analysis -
          <button
            title="Open Chat History in HaiX AI Assistant"
            onClick={() => {
              setSidebarStatus(true);
              setSummaryStatus(false);
            }}
            className="underline text-secondary bottom-0"
          >
            <GoLinkExternal className="text-[16px] text-green-600" />
          </button>
        </div>
      </div>
      {showModal && (
        <Dialog
          title={ModalTitle}
          body={showModalMessage}
          positiveButton1={{
            text: 'Ok',
            callback: () => {},
          }}
          showDialog={showModal}
          setShowDialog={setshowModal}
        />
      )}
    </ReactModal>
  );
};

export default memo(ChatgptSummary);
